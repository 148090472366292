main {
  width: 70%;
  margin: auto;
  text-align: center;
}
#notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #333;
  color: white;
  border-radius: 10px;
  display: none;
}
main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
}

.footer-button-pause-stop-recording {
  display: none !important;
}
.meeting-info-container__wrapper {
  display: none !important;
}
